import React from "react";
import Footer from "../Components/Footer";
import Menu from "../Components/Menu";
import ThemeInfo from "../Components/ThemeInfo";
import ThemeInfo2 from "../Components/ThemeInfo2";

const Elements =()=>{
    return(
        <>
            <Menu/>
            <ThemeInfo/>
            <ThemeInfo2/>
            <Footer/>

        </>
    )
}

export default Elements;