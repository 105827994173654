import React from "react";
import Menu from "../Components/Menu";
import Footer from "../Components/Footer";
import Sginupform from "../Components/Signupform";
const Signup =()=>{
    return(
        <>
            <Menu/>
            <Sginupform/>
            <Footer/>

        </>
    )
}

export default Signup;