import React from "react";
import "../CSS/SupportTicket.css";
import {useState} from 'react';
import { addUser } from "../Service/api";

const defaultValue={
    subject:'',
    message:'',
    filename:''
}

const SupportTicket1 =()=>{

    const [user,setUser] = useState(defaultValue);

    const onValueChange =(e)=>{
    setUser({...user,[e.target.name]:e.target.value})
    }

    const addUserDetails = async()=>{
        await addUser(user);
        alert('Data save')
    }

    return(
        <>  
        <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-md-12 col-sm-12 m-0 p-0">

                <div className="ticket1">
                    <p>Submit a new ticket</p>

                    <form action="/help" onsubmit="myFunction()">

                        <label>Subject</label><br></br>

                        <select name="subject" onClick={(e)=> onValueChange(e)}>
                            <option>Product Update Request (Please specify version needed)</option>
                            <option>Product is Missing Some Files</option>
                            <option>Payment Deducted | Order Not Completed Yet</option>
                            <option>Not Able to Download A Product</option>
                            <option>Need Installation/Updation Support</option>
                            <option>Want to Upgrade/join/Convert My Membership</option>
                            <option>Want to Cancel My Order</option>
                            <option>Other Queries (Please specify with details)</option>
                        </select><br></br><br></br>

                        <label>Message (Max-char: 500)</label><br></br>
                        <textarea  maxlength="500" rows="6" cols="85" name="message" onClick={(e)=> onValueChange(e)}></textarea>

                        <label><br></br>Attachment(s)</label><br></br>
                        <input type="file" id="myfile" onClick={(e)=> onValueChange(e)} name="filename" accept="image/png, image/jpeg"></input>
                
                        <p style={{fontSize:"12px"}}>Note: Upload fle should be .png or .jpg | Maximum file size is 512KB</p>
                        <a href="./contact"><button onClick={()=> addUserDetails()} className="a">Submit</button></a>

                    </form>
                </div>

        </div>
        </div>
        </div>
             
        </>
    )
}
export default SupportTicket1;