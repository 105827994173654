import React from "react";
import {HiOutlineClipboard} from 'react-icons/hi';
import {IoIosNotificationsOutline} from 'react-icons/io';
import {MdOutlineCardMembership,MdContactSupport} from 'react-icons/md';
import {AiOutlineFileDone} from 'react-icons/ai';
import {GoCloudDownload} from 'react-icons/go';
import {FaRegAddressCard} from 'react-icons/fa';
import {MdOutlinePayment,MdOutlineAccountCircle} from 'react-icons/md';
import {FiHeart} from 'react-icons/fi';
import {GoSignOut} from 'react-icons/go';
import "../CSS/Admin.css";
const Admin1=()=>{
    return(
        <>
            <div className="containe-fluid">
            <div className="row d-flex m-0 p-0">

                <div className="col-12 col-md-4">
                <div className="dashbord-sidebar">
                    <ul type="none">
                        <li className="heading">Manage Account</li>
                        <li> <a href="./account" className="active1"><i><HiOutlineClipboard/></i> Dashboard</a></li>
                        <li> <a href="./account"><i id="i"><IoIosNotificationsOutline/></i> Notification<span class="notifi">5</span></a></li>
                        <li> <a href="./account"><i id="i"><MdOutlineCardMembership/></i> Total Users</a></li>
                        <li> <a href="./"> <i id="i"><AiOutlineFileDone/></i>Total Product</a> </li>
                        <li> <a href="./"> <i id="i"><GoCloudDownload/></i>Total Blogs</a> </li>
                        <li> <a href="./"> <i id="i"><FaRegAddressCard/></i>Total Revenue</a> </li>
                        <li> <a href="./"> <i id="i"><MdOutlinePayment/></i>Total Download</a> </li>
                        <li> <a href="./"> <i id="i"><MdOutlineAccountCircle/></i>Email</a> </li>
                        <li> <a href="./"> <i id="i"><MdContactSupport/></i> Support Ticket</a> </li>
                        <li> <a href="./"> <i id="i"><FiHeart/></i>Contact Form Submission</a> </li>
                        <li> <a href="./"> <i id="i"><GoSignOut/></i>Upload Products</a> </li>
                        <li> <a href="./"> <i id="i"><FiHeart/></i>Upload Blogs</a></li>
                        <li> <a href="./"> <i id="i"><GoSignOut/></i>Total Developers</a> </li>
                    </ul>
                </div>
                </div>

                <div className="col-12 col-md-8 top">

                    <div className="d-flex">
                        <div className=" col-sm sec1">
                            <i><HiOutlineClipboard/></i>
                            <p>Dashboard</p>
                        </div>

                        <div className="col-sm sec1">
                            <i><IoIosNotificationsOutline/></i>
                            <p>Notification</p>
                        </div>

                        <div className="col-sm sec1">
                            <i><MdOutlineCardMembership/></i>
                            <p>Total Users</p>
                        </div>

                        <div className="col-sm sec1">
                            <i><AiOutlineFileDone/></i>
                            <p>Total Product</p>
                        </div>
                    </div>

                    <div className="d-flex">

                        <div className="col-sm sec1">
                            <i><GoCloudDownload/></i>
                            <p>Total Blogs</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><FaRegAddressCard/></i>
                            <p>Total Revenue</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><MdOutlinePayment/></i>
                            <p>Total Download</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><MdOutlineAccountCircle/></i>
                            <p>Email</p>
                        </div>

                    </div>

                    <div className="d-flex">    
                        <div className="col-sm sec1">
                            <i><MdContactSupport/></i>
                            <p>Support Ticket</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><FiHeart/></i>
                            <p>Contact Form Submission</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><GoSignOut/></i>
                            <p>Upload Products</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><GoSignOut/></i>
                            <p>Upload Blogs</p>
                        </div>
                    </div>

                    <div className="d-flex">    
                        <div className="col-sm sec1">
                            <i><MdContactSupport/></i>
                            <p>Total Developers</p>
                        </div>
                        {/* <div className="col-sm sec1">
                            <i><FiHeart/></i>
                            <p>Total Products Download</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><GoSignOut/></i>
                            <p>Upload Products</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><GoSignOut/></i>
                            <p>Upload Blogs</p> 
                        </div>*/}
                    </div>
                    
                </div>
            </div>
            </div>
        </>
    )
}
export default Admin1;