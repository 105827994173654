import React from "react";
import Footer from "../Components/Footer";
import Help1 from "../Components/Help1";
import Menu from "../Components/Menu";
const Help =()=>{
    return(
        <>
            <Menu/>
            <Help1/>
            <Footer/>
        </>
    )
}

export default Help;