import React from "react";
import Menu from "../Components/Menu";
import Footer from "../Components/Footer";
import About1 from "../Components/About1";
import About2 from "../Components/About2";
const About =()=>{
    return(
        <>
            <Menu />
            <About1/>
            <About2/>
            <Footer />
        </>
    )
}

export default About;