import React from "react";
import {FaGoogle} from 'react-icons/fa';
import {BsFacebook} from "react-icons/bs";
import {AiFillGithub,AiFillLinkedin} from "react-icons/ai";
import "../CSS/Login.css";

const Loginform=()=>{
    return(
        <>
            <div className="container-fluid">
            <div className="row">
            <div className="col-12 col-md-6 col-sm-12 login-form2">

                    <div className="login-box-left">
                        <img src="Login.jpg"></img>
                    </div>    
                            
                    <div className="login-box-right2">   

                        <h3 style={{color:"white",marginLeft:"00px",marginTop:"0px",paddingBottom:"10px"}}>Login!</h3>
                        
                        <form>
                            <label for="email">Email</label><br></br>
                            <input type="text" id="email" name="email"></input><br></br>

                            <label for="password">Password</label><br></br>
                            <input type="text" id="password" name="password"></input><br></br>
                            
                            <button href="#" style={{float:"left",color:"#81b441"}}>Login</button>
                            
                            <div className="checkbox1">
                            <label for="checkbox" id="checkbox" name="checkbox" style={{marginLeft:"6px"}}>Remember me</label>
                            </div>
                                
                            <input type="checkbox" className="checkbox" style={{float:"right",margin:"-18px"}} ></input>
                            
                            <a href="#" style={{float:"left",color:"#81b441",fontSize:"15px",marginLeft:"6px",marginTop:"20px"}}>Forgot password?</a><br></br>
                            <div className="create-account" style={{fontSize:"15px"}}><p>Don't have an account? <a href="/signup" style={{color:"#81b441"}}>Sign up</a></p></div>
                        </form>
                        <div className="button2">
                            <button><FaGoogle/></button>
                            <button><BsFacebook/></button>
                            <button><AiFillGithub/></button>
                            <button><AiFillLinkedin/></button>
                        </div>
                    </div>
            </div>
            </div>
            </div>
        </>
    )
}
export default Loginform;