import React from "react";
import Footer from "../Components/Footer";
import Menu from "../Components/Menu";
import Filter from "../Components/Filter";
import Grid3 from "../Components/Grid3";
const Themes =()=>{
    return(
        <> 
            <Menu/>
            <Filter />
            <Grid3 />
            <Footer />
        </>
    )
}

export default Themes;