import React from 'react';
import './App.css';
import Home from './Main/Home';
import Elements from './Main/Elements';
import Themes from './Main/Themes';
import About from './Main/About';
import Blog from './Main/Blog';
import Help from './Main/Help';
import Cart from './Main/Cart';
import Login from './Main/Login';
import Signup from './Main/Signup';
import Contact from './Main/Contact';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import ReadBlog from './Main/ReadBlog';
import Checkout from './Main/Checkout';
import Payment from './Main/Payment';
import Account from './Main/Account';
import Admin from './Main/Admin';
import Developer from './Main/Developer';
import DeveloperPanel from './Main/DeveloperPanel';
import Info from './Main/Info';
import BillingAddress from './Main/BillingAddress';
import SupportTicket from './Main/SupportTicket';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path= "/" element={<Home/>}> </Route>
          <Route exact path= "/home" element={<Home/>}> </Route>
          <Route exact path= "/themes" element={<Themes/>}> </Route>
          <Route exact path= "/theme-info" element={<Elements/>}> </Route>
          <Route exact path= "/about" element={<About/>}> </Route>
          <Route exact path= "/contact" element={<Contact/>}> </Route>
          <Route exact path= "/blog" element={<Blog/>}> </Route>
          <Route exact path= "/blog-read" element={<ReadBlog/>}> </Route>
          <Route exact path= "/help" element={<Help/>}> </Route>
          <Route exact path= "/cart" element={<Cart/>}> </Route> 
          <Route exact path= "/checkout" element={<Checkout/>}> </Route>
          <Route exact path= "/payment" element={<Payment/>}> </Route>
          <Route exact path= "/login" element={<Login/>}> </Route>
          <Route exact path= "/signup" element={<Signup/>}> </Route>
          <Route exact path= "/account" element={<Account/>}> </Route>
          <Route exact path= "/admin" element={<Admin/>}> </Route>
          <Route exact path= "/developer" element={<Developer/>}> </Route>
          <Route exact path= "/developer-panel" element={<DeveloperPanel/>}> </Route>
          <Route exact path= "/info" element={<Info/>}> </Route>
          <Route exact path= "/billing-address" element={<BillingAddress/>}> </Route>
          <Route exact path= "/support-ticket" element={<SupportTicket/>}> </Route>
        </Routes>
      </BrowserRouter> 
    </div>
  )
}

export default App;
