import React from "react";
import Dashboard from "../Components/Dashboard";
import Footer from "../Components/Footer";
import Menu from "../Components/Menu";
const Account =()=>{
    return(
        <>
            <Menu/>
            <Dashboard/>
            <Footer/>
        </>
    )
}

export default Account;