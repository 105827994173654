import React from "react";
import Footer from "../Components/Footer";
import Menu from "../Components/Menu";
const Developer =()=>{
    return(
        <>
            <Menu/>
            <p> </p>
            <Footer/>
        </>
    )
}

export default Developer;