import React from "react";
import {FaGoogle} from 'react-icons/fa';
import {BsFacebook} from "react-icons/bs";
import {AiFillGithub,AiFillLinkedin} from "react-icons/ai";
import "../CSS/Signup.css";

import {useState} from "react";
import {addUser} from "../Service/api";
// import {NavLink} from "react-router-dom";
// import {useNavigate} from "react-router-dom";
    
    const Sginupform = () =>{

        const defaultValue = {
            email:'',
            confirmpassword:'',
            RememberPassword:''
         }

        const [user, setUser] = useState(defaultValue);
     
        const onValueChange = (e) =>{
           // console.log(e.target.name, e.target.value);
           setUser({...user, [e.target.name]: e.target.value});
           console.log(user);
        }
          
        const addUserDetails = async() =>{
           await addUser(user);
           alert('Data Successfully Inserted');   
        }

    return(
        <>
            
            <div className="container-fluid">
            <div className="row">
            <div className="col-12 col-md-6 col-sm-12 login-form">

                    <div className="login-box-left">
                        <img src="Login.jpg"></img>
                    </div>
                            
                    <div className="login-box-right">
                        
                        <h3 style={{color:"white",marginLeft:"00px",marginTop:"0px",paddingBottom:"10px"}}>Signup!</h3>
                        
                        <form>
                            <label for="email">Email</label>
                            <input type="text" id="email" onChange={(e) => onValueChange(e)} name="email"></input>

                            <label for="createpassword">Create Password</label>
                            <input type="text" id="createpassword" onChange={(e)=>onValueChange(e)} name="createpassword"></input>

                            <label for="confirmpassword">Confirm Password</label>
                            <input type="text" id="confirmpassword" onChange={(e)=>onValueChange(e)} name="confirmpassword"></input>
                                     
                            <button href="#" onClick={() => addUserDetails()} style={{float:"left",color:"#81b441"}}>Signup</button>
                        
                            <div className="checkbox1">
                            <label for="checkbox" id="checkbox" onChange={(e)=>onValueChange(e)} name="checkbox" style={{marginLeft:"6px"}}>Remember me</label>
                            </div>
                            
                            <input type="checkbox" onChange={(e)=>onValueChange(e)} className="checkbox" style={{float:"right",margin:"-18px",paddingLeft:"-18px"}} ></input>
                                 
                            <div className="create-account" style={{float:"left",color:"#81b441",fontSize:"15px",marginLeft:"6px",marginTop:"20px"}}><p>Already have an account? <a href="/login" style={{color:"#81b441"}}>Login</a></p></div>        
                        </form>

                        <div className="button2">
                            <button><FaGoogle/></button>
                            <button><BsFacebook/></button>
                            <button><AiFillGithub/></button>
                            <button><AiFillLinkedin/></button>
                        </div>
                        
                    </div>
            </div>
            </div>
            </div>
        </>
    )
}
export default Sginupform;