import React from "react";
import "../CSS/Checkout.css";
const Checkout1=()=>{
    return(
        <>
        <div className="container-fluid">
        <div className="row">

            <div className="col-12 col-md-12 col-sm-12 checkout">
                <p>Returning customer? <a href="#" >Click here to login</a></p>
                <p>Have a coupon? <a href="#">click here to enter your code</a></p>
            </div>

            <div className="d-flex">

                <div className="col-6 col-md-6 col-sm-12 address">
                    <form>
                        <h1>BILLING DETAILS</h1>
                        <div className="d-flex">
                            
                            <div className="mar">
                                <label>First Name</label><br></br>
                                <input type="text"></input>
                            </div>

                            <div>
                                <label>Last Name</label><br></br>
                                <input type="text"></input>
                            </div>
                        </div>

                        <div  className="d-flex">
                        <div className="mar">
                        <label>Country / Region</label><br></br>
                        <input type="text"></input>
                        </div>
                        <div>
                        <label>State</label><br></br>
                        <input type="text"></input>
                        </div>
                        </div>

                        <div  className="d-flex">
                            <div className="mar">
                            <label>Street address</label><br></br>
                            <input type="text"></input>
                            </div>

                            <div>
                            <label>Town / City</label><br></br>
                            <input type="text"></input>
                            </div>
                        </div>

                        <div  className="d-flex">
                        <div className="mar">
                        <label>PIN Code</label><br></br>
                        <input type="text"></input>
                        </div>

                        <div>
                        <label>Phone Number</label><br></br>
                        <input type="text"></input>
                        </div>
                        </div>
                        
                        <div>
                        <label className="email-font">Email</label><br></br>
                        <input type="email" className="email"></input>
                        </div>                        
                    </form>

                </div>

                <div className="col-6 col-md-6 col-sm-12 payment">

                    <table>
                        <thead className="d-flex">
                            <thead className="left">PRODUCT</thead>
                            <thead className="right">SUBTOTAL</thead>
                        </thead>
                        
                        <tbody>
                            <tr>
                                <td>Newspaper Theme GPL v12.1.1 | News & WooCommerce WordPress Theme  × 2</td>
                                <td>6.20$</td>
                            </tr>
                            <tr>
                                <td>Newspaper Theme GPL v12.1.1 | News & WooCommerce WordPress Theme  × 2</td>
                                <td>6.20$</td>
                            </tr>
                        </tbody>

                        <tfoot>
                                <tr>
                                    <td>Subtotal</td>
                                    <td>12.12$</td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <td>12.12$</td>
                                </tr>
                        </tfoot>
                    </table>

                    <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in <a href="#">our privacy policy.</a>our privacy policy.</p>
                    
                    <form>
                    <input type="checkbox"></input> I have read and agree to the website <a>terms and conditions *</a>
                    <button>PLACE ORDER</button>
                    </form>

                </div>

            </div>

        </div>
        </div> 
        </>
    )
}
export default Checkout1;