import React from "react";
import Footer from "../Components/Footer";
import Menu from "../Components/Menu";
import Admin1 from "../Components/Admin1";
const Admin =()=>{
    return(
        <>
            <Menu/>
            <Admin1/>
            <Footer/>
        </>
    )
}

export default Admin;