import React from "react";
import "../CSS/Blogs.css";
import BlogsCall from "./BlogsCall";
import { useState } from "react";
import {BsFillPersonFill,BsCalendar2Date} from "react-icons/bs"

const Blogs=()=>{
    const[item,setItem]=useState(BlogsCall);
    return(
        <>
        <div className="Container-fluid" style={{marginTop:"-14px"}}>
        <div className="row m-0 p-0">
        
            {   
                item.map((elem)=>{
                const{id,img,date,title,author,para,url}=elem;
                return(
                    <>
                    <div className="col-12 col-md-4 col-sm-12 a1" >
                    <div className="col-12 col-md-12 col-sm-12">
                        <div className="blog">
                        <img src={img}></img>
                        <p id="abc"><i><BsCalendar2Date/></i>{date}</p>
                        <h4>{title}</h4>
                        <p>By <i><BsFillPersonFill/> </i><b>{author}</b></p>
                        <p>{para}</p>
                        <a href={url}><button>Read More</button></a>
                        </div>
                    </div>
                    </div>
                    </>
                )
                })     
            }
         
        </div>
        </div>
        </>
    ) 
}
export default Blogs;