import React from "react";
import "../CSS/Cart.css";
import {AiOutlineMinus,AiOutlinePlus} from 'react-icons/ai';
import {RiDeleteBin6Line} from 'react-icons/ri';
const Cart1=()=>{
    return(
        <> 
            <div className="container desktop">
                <div className="row">
                    <div className="col-12 col-md-12 col-sm-12">
                        <table className="cart1">
                            <tr className="r1">
                                <th> </th>
                                <th> </th>
                                <th className="cart-title1">PRODUCT</th>
                                <th className="cart-price">PRICE</th>
                                <th>QUANTITY</th>
                                <th className="subtotal">SUBTOTAL</th>
                            </tr>

                            <tr className="r2">
                            <td><i><RiDeleteBin6Line/></i></td>
                            <td><img src="img13.png" width="70"></img></td>
                            <td className="cart-title"> <a href="#">Newspaper Theme GPL v12.1.1 | News & WooCommerce WordPress Theme</a></td>
                            <td className="cart-price">₹221.00</td>
                            <td><button><i><AiOutlineMinus/></i></button> <button>1</button> <button><i><AiOutlinePlus/></i></button></td>
                            <td className="subtotal">₹442.00</td>
                            </tr>

                            <tr className="r2">
                            <td><i><RiDeleteBin6Line/></i></td>
                            <td><img src="img13.png" width="70"></img></td>
                            <td className="cart-title"><a href="#">Newspaper Theme GPL v12.1.1 | News & WooCommerce WordPress Theme</a></td>
                            <td className="cart-price">₹221.00</td>
                            <td><button><i><AiOutlineMinus/></i></button> <button>1</button> <button><i><AiOutlinePlus/></i></button></td>
                            <td className="subtotal">₹442.00</td>
                            </tr>

                            <tr className="r2">
                            <td><i><RiDeleteBin6Line/></i></td>
                            <td><img src="img13.png" width="70"></img></td>
                            <td className="cart-title"><a href="#">Newspaper Theme GPL v12.1.1 | News & WooCommerce WordPress Theme</a></td>
                            <td className="cart-price">₹221.00</td>
                            <td><button><i><AiOutlineMinus/></i></button> <button>1</button> <button><i><AiOutlinePlus/></i></button></td>
                            <td className="subtotal">₹442.00</td>
                            </tr>

                        </table>
                        <div className="subtotal-box">
                            <h2>Cart Totals</h2>
                            <div className="d-flex sb1">
                                <p className="sb2">Subtotal</p>
                                <p>₹4,320.00</p>
                            </div>
                            <div className="d-flex sb3">
                                <p className="sb4">Total</p>
                                <p>₹4,320.00</p>
                            </div>
                            <button>Proceed to Checkout</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mobile">
                <div className="row">
                    <div className="col-12 col-md-12 col-sm-12">

                        <div className="subtotal-box">
                            <div className="d-flex sb1">
                                <p className="sb2">Subtotal</p>
                                <p className="sb6">₹4,320.00</p>
                            </div>
                            <div className="d-flex sb3">
                                <p className="sb4">Total</p>
                                <p>₹4,320.00</p>
                            </div>
                            <button>Proceed to Buy</button>
                        </div>

                        
                            <table className="cart1">
                                <tr className="r2">
                                    <td><img src="img13.png"></img></td>
                                    <td className="cart-title"> <a href="#">Newspaper Theme GPL v12.1.1 | News & WooCommerce WordPress Theme</a></td>
                                    
                                    <td className="subtotal">₹442.00</td> 
                                </tr>
                                <tr className="r3">
                                    <td className="cart-price">₹221.00</td>
                                    <td><button><i><AiOutlineMinus/></i></button> <button>1</button> <button><i><AiOutlinePlus/></i></button></td>
                                    <td><i><RiDeleteBin6Line/></i></td>
                                </tr>
                            </table>
                        

                        <div>
                        <table className="cart1">
                            <tr className="r2">
                                <td><img src="img13.png"></img></td>
                                <td className="cart-title"> <a href="#">Newspaper Theme GPL v12.1.1 | News & WooCommerce WordPress Theme</a></td>
                                
                                <td className="subtotal">₹442.00</td> 
                            </tr>
                            <tr className="r3">
                                <td className="cart-price">₹221.00</td>
                                <td><button><i><AiOutlineMinus/></i></button> <button>1</button> <button><i><AiOutlinePlus/></i></button></td>
                                <td><i><RiDeleteBin6Line/></i></td>
                            </tr>
                        </table>
                        </div>
                        
                        <div>  
                        <table className="cart1">
                            <tr className="r2">
                                <td><img src="img13.png"></img></td>
                                <td className="cart-title"> <a href="#">Newspaper Theme GPL v12.1.1 | News & WooCommerce WordPress Theme</a></td>
                                
                                <td className="subtotal">₹442.00</td> 
                            </tr>
                            <tr className="r3">
                                <td className="cart-price">₹221.00</td>
                                <td><button><i><AiOutlineMinus/></i></button> <button>1</button> <button><i><AiOutlinePlus/></i></button></td>
                                <td><i><RiDeleteBin6Line/></i></td>
                            </tr>
                        </table>
                        </div>


                     
                    </div>
                </div>
            </div>

        </>
    )
}
export default Cart1;