const GridCall = [
    {
        id:1,
        title:"WordPress Themes",
        discription:"Thousands of WordPress themes",
        url1:"/",
        url2:"/home",
        image:"img10.png",

    },
    {
        id:2,
        title:"WordPress Themes",
        discription:"Thousands of WordPress themes",
        url1:"/",
        url2:"/home",
        image:"img12.png",

    },
    {
        id:3,
        title:"WordPress Themes",
        discription:"Thousands of WordPress themes",
        url1:"/",
        url2:"/home",
        image:"img13.png",

    },
    {
        id:4,
        title:"WordPress Themes",
        discription:"Thousands of WordPress themes",
        url1:"/",
        url2:"/home",
        image:"img14.png",

    },
    {
        id:5,
        title:"WordPress Themes",
        discription:"Thousands of WordPress themes",
        url1:"/",
        url2:"/home",
        image:"img17.png",

    },
    {
        id:6,
        title:"WordPress Themes",
        discription:"Thousands of WordPress themes",
        url1:"/",
        url2:"/home",
        image:"img18.png",

    },
    {
        id:7,
        title:"WordPress Themes",
        discription:"Thousands of WordPress themes",
        url1:"/",
        url2:"/home",
        image:"img10.png",

    },
    {
        id:8,
        title:"WordPress Themes",
        discription:"Thousands of WordPress themes",
        url1:"/",
        url2:"/home",
        image:"img19.png",

    },
    {
        id:9,
        title:"WordPress Themes",
        discription:"Thousands of WordPress themes",
        url1:"/",
        url2:"/home",
        image:"img13.png",
    }
]
export default GridCall;