import React from "react";
import {BsCalendar2Date} from "react-icons/bs";
import {BiCommentDots} from "react-icons/bi";
import {FiEye} from "react-icons/fi";
import {AiFillFacebook,AiOutlineSkype,AiFillLinkedin} from "react-icons/ai";
import {FaInstagram} from "react-icons/fa";
import {IoLogoWhatsapp} from "react-icons/io";
import {TbBrandTelegram} from "react-icons/tb";
import "../CSS/BlogSingle.css";

const BlogSingle =()=>{
    return(
    <>
        <div className="container">
        <div className="row">
        <div className="col-12 col-md-12 col-sm-12 m-0 p-0">

            <div className="blog-read1">

                <img src="blog-single.jpg"></img>
                <h2>Let's explore 5 cool new features in JobBoard theme</h2>
                <div className="d-flex blog1" >
                    <a href="#"><i><BsCalendar2Date/> </i>20th March 2022</a>
                    <a href="#"><i><BiCommentDots/> </i>35 Comments</a>
                    <a href="#"><i><FiEye/> </i>55 View</a>
                </div>

                <p>We denounce with righteous indige nation and dislike men who are so 
                beguiled and demo realized by the charms of pleasure of the moment, so 
                blinded by desire, that they cannot foresee the pain and trouble that 
                are bound to ensue; and equal blame belongs to those who fail in their 
                duty through weakness of will, which is the same as saying through 
                shrinking from toil and pain. These cases are perfectly simple and 
                easy to distinguish. In a free hour, when our power of choice is 
                untrammelled and when nothing prevents our being able to do what we 
                like best, every pleasure is to be welcomed and every pain avoided.</p>
                <div className="blog-read2 d-flex">
                    <img src="blog-single2.jpeg" style={{marginRight:"40px"}} ></img>
                    <img src="blog-single3.jpeg" ></img>                
                </div>
                
                <h2>A cleansing hot shower or bath</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                 tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                   consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                     non proident, sunt in culpa qui officia.</p>
                <h2>A cleansing hot shower or bath</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                 incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                   in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                    sint occaecat cupidatat non proident, sunt in culpa qui officia.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                 ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in v
                  oluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidata
                  t non proident, sunt in culpa qui officia.</p>

                <h2>Related Tags</h2>
                <div className="blog-read4 d-flex">
                    <button src="#">Announcement</button>
                    <button src="#">Experiences</button>
                    <button src="#">Market News</button>
                </div>
                
                <h2>Social Share</h2>
                <div className="blog-read5 d-flex">
                    <button src="#"><i><AiFillFacebook/></i></button>
                    <button src="#"><i><FaInstagram/></i></button>
                    <button src="#"><i><IoLogoWhatsapp/></i></button>
                    <button src="#"><i><TbBrandTelegram/></i></button>
                    <button src="#"><i><AiOutlineSkype/></i></button>
                    <button src="#"><i><AiFillLinkedin/></i></button>
                </div>
            </div>
        </div>
        </div>
        </div>

    </>
    )
};
export default BlogSingle;