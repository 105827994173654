import React from "react";
const BlogsCall = [
    {
        id:1,
        img:"img12.png",
        date:"12th Aug 2022",
        title:"Fix – this website is using an illegal copy of JNews theme",
        author:"Real Gpl Theme",
        para:"This website is using an illegal copy of the JNews theme - Do you want to fix this JNews Theme error? Well, we have a paid solution for you. This problem generally occurs when you are using Nulled, Cracked, or...",
        url:"https://www.realgpl.com/fix-illegal-copy-of-jnews-theme-error/",
    },
    {
        id:2,
        img:"img12.png",
        date:"12th Aug 2022",
        title:"Fix – this website is using an illegal copy of JNews theme",
        author:"Real Gpl Theme",
        para:"This website is using an illegal copy of the JNews theme - Do you want to fix this JNews Theme error? Well, we have a paid solution for you. This problem generally occurs when you are using Nulled, Cracked, or...",
        url:"https://www.realgpl.com/fix-illegal-copy-of-jnews-theme-error/",
    },
    {
        id:3,
        img:"img12.png",
        date:"12th Aug 2022",
        title:"Fix – this website is using an illegal copy of JNews theme",
        author:"Real Gpl Theme",
        para:"This website is using an illegal copy of the JNews theme - Do you want to fix this JNews Theme error? Well, we have a paid solution for you. This problem generally occurs when you are using Nulled, Cracked, or...",
        url:"https://www.realgpl.com/fix-illegal-copy-of-jnews-theme-error/",
    },
    {
        id:4,
        img:"img12.png",
        date:"12th Aug 2022",
        title:"Fix – this website is using an illegal copy of JNews theme",
        author:"Real Gpl Theme",
        para:"This website is using an illegal copy of the JNews theme - Do you want to fix this JNews Theme error? Well, we have a paid solution for you. This problem generally occurs when you are using Nulled, Cracked, or...",
        url:"https://www.realgpl.com/fix-illegal-copy-of-jnews-theme-error/",
    },
    {
        id:5,
        img:"img12.png",
        date:"12th Aug 2022",
        title:"Fix – this website is using an illegal copy of JNews theme",
        author:"Real Gpl Theme",
        para:"This website is using an illegal copy of the JNews theme - Do you want to fix this JNews Theme error? Well, we have a paid solution for you. This problem generally occurs when you are using Nulled, Cracked, or...",
        url:"https://www.realgpl.com/fix-illegal-copy-of-jnews-theme-error/",
    },
    {
        id:6,
        img:"img12.png",
        date:"12th Aug 2022",
        title:"Fix – this website is using an illegal copy of JNews theme",
        author:"Real Gpl Theme",
        para:"This website is using an illegal copy of the JNews theme - Do you want to fix this JNews Theme error? Well, we have a paid solution for you. This problem generally occurs when you are using Nulled, Cracked, or...",
        url:"https://www.realgpl.com/fix-illegal-copy-of-jnews-theme-error/",
    },
]
export default BlogsCall;