import React from "react";
import "../CSS/Filterc.css"
const Filter=()=>{
    return(
        <>
        <div className="container-fluid color">
            <div className="row">
                <div className="col-12 col-md-12 col-sm-12 filter">
                
                    <div className="myBtnContainer1">
                        <button class="btn1 active" onclick="filterSelection('all')"> Show all</button>
                        <button class="btn1" onclick="filterSelection('cars')"> Cars</button>
                        <button class="btn1" onclick="filterSelection('animals')"> Animals</button>
                        <button class="btn1" onclick="filterSelection('fruits')"> Fruits</button>
                        <button class="btn1" onclick="filterSelection('colors')"> Colors</button>
                        <button class="btn1" onclick="filterSelection('cars')"> Cars</button>
                        <button class="btn1" onclick="filterSelection('animals')"> Animals</button>
                        <button class="btn1" onclick="filterSelection('fruits')"> Fruits</button>
                        <button class="btn1" onclick="filterSelection('colors')"> Colors</button>
                        <button class="btn1" onclick="filterSelection('cars')"> Cars</button>
                        <button class="btn1" onclick="filterSelection('animals')"> Animals</button>
                        <button class="btn1" onclick="filterSelection('fruits')"> Fruits</button>
                        <button class="btn1" onclick="filterSelection('colors')"> Colors</button>
                    </div>
                    <div className="option">
                        <select className="options">
                            <option>select</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                        </select>
                    </div>
                
                </div>
            </div>
        </div>        
        </>
    )
}
export default Filter;