import React from "react";
import "../CSS/Contact1.css"
import { useState } from "react";
import { addUser } from "../Service/api";

const defaultValue = {
    name:'',
    company:'',
    email:'',
    phone:'',
    subject:'',
    projctDetails:'',
    message:''
}
const Contact1 =()=>{

const [user,setUser] = useState(defaultValue);

const onValueChange =(e)=>{
    setUser({...user,[e.target.name]:e.target.value });
}

const addUserDetails = async()=> {
    await addUser(user);
    alert('Data save')

}
                       
    return(
        <> 
        <div className="container-fluid background">
        <div className="row">
        <div className="col-12 col-md-12 col-sm-12 m-0 p-0">
            <div className="contact1" style={{backgroundImage:"url('contact.jpg')"}}>
                <h1>Contact: Agency</h1>
                <p>Leave us a little info, and we’ll be in touch.</p>
                <a href="./contact"><button>Send Us an Email</button></a>
                <div id="address">
                    <h5>Address</h5><br></br>
                    <h6>Kolkata</h6>
                    <p>365 Gran Via de Corts<br></br>
                    Catalanes, BA 08015, Spain</p>
                    <h5>+91.834.015.9701</h5><br></br>
                    <h6>Assistance hours:</h6>
                    <p>Monday – Friday <br></br>6 am to 8 pm EST (toll free)</p>
                </div>
            </div>

            <div className="d-flex contact-3">
            <div className="col-12 col-md-3">
            <h4>Work inquiries</h4>
            <p>Fill in this form or send us an e-mail with your inquiry.</p>
            </div>

            <div className="col-12 col-md-9">
            <div className="contact2 d-flex">
                <form action="/contact">
                <div className="d-flex b1">
                    <div>
                    <label for="name">Name (required)</label><br></br>
                    <input onChange={(e)=>onValueChange(e)} name="name" type="text" id="name" required></input>
                    </div>

                    <div>
                    <label for="company">Company (required)</label><br></br>
                    <input onChange={(e)=>onValueChange(e)} name="company" type="text" id="Company" required></input><br></br>
                    </div>

                    <div>
                    <label>Email (required)</label><br></br>
                    <input onChange={(e)=>onValueChange(e)} name="email" type="email" id="email" required></input><br></br>
                    </div>
                </div>
                <div className="d-flex b1">    
                    <div>
                    <label>Phone (optional)</label><br></br>
                    <input onChange={(e)=>onValueChange(e)} name="phone" type="phone" id="phone"></input><br></br>
                    </div>

                    <div>
                    <label>Subject (optional)</label><br></br>
                    <input onChange={(e)=>onValueChange(e)} name="subject" type="text" id="subject" ></input><br></br>
                    </div>

                    <div>
                    <label>Project details</label><br></br>
                    <select id="msg1" onChange={(e)=>onValueChange(e)} name="projctDetails">
                        <option>Less than 5K</option>
                        <option>5K - 10K </option>
                        <option>Over 10K</option>
                        <option>Please advice</option>
                    </select><br></br>
                    </div>
                </div>
                    <div>
                    <label>Project details</label><br></br>
                    <textarea onChange={(e)=>onValueChange(e)} name="projctDetails" rows="6" cols="117" id="msg"  required></textarea><br></br>
                    </div>
                    
                    <div class="d-flex">
                    <input type="checkbox" id="input" required></input>
                    <label id="boxpara">I’m okay with getting emails and having that activity tracked to improve my experience.</label>
                    </div>
                    <input onClick={()=> addUserDetails()} type="submit" value="Submit" id="submit"></input>
                    </form>
                </div>
                </div>

            </div>
            </div>
            </div>
        </div>
        </>
    )
}
export default Contact1;