import React from "react";
import "../CSS/AccountInfo.css";
import "../CSS/Help1.css";
const AccountInfo =()=>{
    return(
        <>  
        <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-md-12 col-sm-12 body m-0 p-0">
                
                <form>
                    <h2>ACCOUNT</h2>
                    <div className="d-flex">
                        <div className="mar1">
                            <label>First Name</label><br></br>
                            <input type="text"></input>
                        </div>
                        <div>
                            <label>Last Name</label><br></br>
                            <input type="text"></input>
                        </div>
                    </div>

                    <div  className="d-flex">
                        <div className="mar1">
                            <label>Email</label><br></br>
                            <input type="text"></input>
                        </div>
                        <div>
                            <label>Password</label><br></br>
                            <input type="text"></input>
                        </div>
                    </div> 

                    <div  className="d-flex">

                        <div className="mar1 ">
                            <button>Reset Password</button><br></br>                 
                        </div>

                        <div className="mar2">
                        <button>Save</button><br></br>   
                        </div>

                    </div>                     
                </form>
                
                <form>
                    <h2>BILLING DETAILS</h2>
                    <div className="d-flex">
                        <div className="mar1">
                            <label>First Name</label><br></br>
                            <input type="text"></input>
                        </div>
                        <div>
                            <label>Last Name</label><br></br>
                            <input type="text"></input>
                        </div>
                    </div>

                    <div  className="d-flex">
                        <div className="mar1">
                            <label>Country / Region</label><br></br>
                            <input type="text"></input>
                        </div>
                        <div>
                            <label>State</label><br></br>
                            <input type="text"></input>
                        </div>
                    </div>

                    <div  className="d-flex">
                        <div className="mar1">
                            <label>Street address</label><br></br>
                            <input type="text"></input>
                        </div>

                        <div>
                            <label>Town / City</label><br></br>
                            <input type="text"></input>
                        </div>
                    </div>

                    <div  className="d-flex">
                        <div className="mar1">
                            <label>PIN Code</label><br></br>
                            <input type="text"></input>
                        </div>

                        <div>
                            <label>Phone Number</label><br></br>
                            <input type="text"></input>
                        </div>
                    </div>
                        
                    <div>
                        <label className="email-font1">Email</label><br></br>
                        <input type="email" className="email1"></input>
                    </div>

                    <div className="mar1">
                        <button>Save</button><br></br>   
                    </div>                        
                </form>

                {/* <div className="ticket">
                    <p>Submit a new ticket</p>

                    <form action="/help" onsubmit="myFunction()">

                        <label>Subject</label><br></br>

                        <select>
                            <option>Product Update Request (Please specify version needed)</option>
                            <option>Product is Missing Some Files</option>
                            <option>Payment Deducted | Order Not Completed Yet</option>
                            <option>Not Able to Download A Product</option>
                            <option>Need Installation/Updation Support</option>
                            <option>Want to Upgrade/join/Convert My Membership</option>
                            <option>Want to Cancel My Order</option>
                            <option>Other Queries (Please specify with details)</option>
                        </select><br></br><br></br>

                        <label>Message (Max-char: 500)</label><br></br>
                        <textarea  maxlength="500" rows="6" cols="133"></textarea>

                        <label><br></br>Attachment(s)</label><br></br>
                        <input type="file" id="myfile" name="filename" accept="image/png, image/jpeg"></input>
                
                        <p style={{fontSize:"12px"}}>Note: Upload fle should be .png or .jpg | Maximum file size is 512KB</p>
                        <a href="./contact"><button className="a">Submit</button></a>

                    </form>
                </div> */}
        </div>
        </div>
        </div>
             
        </>
    )
}
export default AccountInfo;