import React from "react";
import { NavLink } from "react-router-dom";
import "../CSS/AllCategory.css";
const AllCategory =()=>{
    return(
    <>  
        <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-md-12 AllCategory">
        <nav>
            <ul type="none" className="d-flex">
                <div class="col-sm">
                    <li><NavLink to = "/" className="categorylist">Tamplates</NavLink></li>
                </div>
                <div class="col-sm">
                    <li><NavLink to = "/" className="categorylist">WordPress</NavLink></li>
                </div>
                <div class="col-sm">
                    <li><NavLink to = "/" className="categorylist">CoursesTheme</NavLink></li>
                </div>
                <div class="col-sm">
                    <li><NavLink to = "/" className="categorylist">UI</NavLink></li>
                </div>
                <div class="col-sm">
                    <li><NavLink to = "/" className="categorylist">eCommerce</NavLink></li>
                </div>
                <div class="col-sm">
                    <li><NavLink to = "/" className="categorylist">TamplateKits</NavLink></li>
                </div>
            </ul> 

            <ul type="none" className="d-flex">
                <div class="col-sm">
                    <li><NavLink to = "/" className="categorylist">Marketing</NavLink></li>
                </div>
                <div class="col-sm">
                    <li><NavLink to = "/" className="categorylist">CMS</NavLink></li>
                </div>
                <div class="col-sm">
                    <li><NavLink to = "/" className="categorylist">Muse</NavLink></li>
                </div>
                <div class="col-sm">
                    <li><NavLink to = "/" className="categorylist">Blogging</NavLink></li>
                </div>
                <div class="col-sm">
                    <li><NavLink to = "/" className="categorylist">Jamstact</NavLink></li>
                </div>
                <div class="col-sm">
                    <li><NavLink to = "/" className="categorylist">Job Theme</NavLink></li>
                </div>
            </ul>

        </nav>
        </div>
        </div>
        </div>
    </>
    )
}

export default AllCategory;