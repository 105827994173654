import React from "react";
import Footer from "../Components/Footer";
import Menu from "../Components/Menu";
import Loginform from "../Components/Loginform";
const Login=()=>{
    return(
        <>
            <Menu/>
            <Loginform />
            <Footer/>
        </>
    )
}

export default Login;