import React from 'react';
import "../CSS/Help1.css";
import "../CSS/Contact1.css";
import "../JS/Help.js"

import { useState } from 'react';
import { addUser } from '../Service/api';

const defaultValue = {
    subject:'',
    message:'',
    filename:''
}

const Help1=()=>{

    const[user,setUser] = useState(defaultValue)
    
    const onValueChange =(e)=>{
        setUser({...user,[e.target.name]:e.target.value});
    }

    const addUserDetails = async()=>{
        await addUser(user);
        alert('Data save');
    }

    return(
        <>
        <div className="container-fluid help">
        <div className="row">
        <div className="col-12 col-md-12 col-sm-12 mt-0 p-0">

        <div className="contact1" style={{backgroundImage:"url('contact.jpg')",marginTop:"-50px"}}>
            <h1>Help & Support</h1>
            <p>Leave us a little info, and we’ll be in touch.</p>
            <a href="./contact"><button>Send Us an Email</button></a>
            <div id="address">
                <h5>Address</h5><br></br>
                <h6>Kolkata</h6>
                <p>365 Gran Via de Corts<br></br>
                    Catalanes, BA 08015, Spain</p>
                <h5>+91.834.015.9701</h5><br></br>
                <h6>Assistance hours:</h6>
                <p>Monday – Friday <br></br>6 am to 8 pm EST (toll free)</p>
            </div>
        </div>

        <div className="help1">
        <p><b>NOTE:</b> All tickets will be <b>answered between IST 8:00 AM to 10:00 PM.</b> For faster response make sure to add <b>WordPress, cPanel details, or screenshots,</b> if you think it would be necessary.</p>
        <p><b>All closed tickets will be deleted within 24 hours from the system.</b></p>
        <p>You can also email your queries at <a href="#">realgplofficial@gmail.com</a></p>
        <br></br><br></br><br></br>
        <nav>
            <ul type="none" className='d-flex'>
                <li>Sort tickets from:<br></br><select>
                    <option>Newer to Older</option>
                    <option>Older to Newer</option>
                </select></li>
                <li>Filter by status:<br></br><select>
                    <option>Newer to Older</option>
                    <option>Older to Newer</option>
                </select></li>
                <a><li><button>New Tickets</button></li></a>
            </ul>
        </nav>
        </div>

        <div className="ticket">
        <p>Submit a new ticket</p>
        <form action="/help" onsubmit="myFunction()">
            <label>Subject</label><br></br>

            <select onChange={(e)=> onValueChange(e)} name="subject">
                <option>Product Update Request (Please specify version needed)</option>
                <option>Product is Missing Some Files</option>
                <option>Payment Deducted | Order Not Completed Yet</option>
                <option>Not Able to Download A Product</option>
                <option>Need Installation/Updation Support</option>
                <option>Want to Upgrade/join/Convert My Membership</option>
                <option>Want to Cancel My Order</option>
                <option>Other Queries (Please specify with details)</option>
            </select><br></br><br></br>

            <label>Message (Max-char: 500)</label><br></br>
            <textarea  maxlength="500" rows="6" cols="133" onChange={(e)=> onValueChange(e)} name="message"></textarea>

            <label><br></br>Attachment(s)</label><br></br>
            <input type="file" id="myfile" onChange={(e)=>onValueChange(e)} name="filename" accept="image/png, image/jpeg"></input>
            
            <p style={{fontSize:"12px"}}>Note: Upload fle should be .png or .jpg | Maximum file size is 512KB</p>
            <a href="./contact"><button className="a" onClick={()=> addUserDetails()} >Submit</button></a>

        </form>
        </div>

        
        </div>
        </div>
        </div>

        </>
    )
}
export default Help1;