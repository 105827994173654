import React from "react";
import {NavLink} from 'react-router-dom';
import logo from '../Image/logo.png';
import {AiOutlineShoppingCart} from 'react-icons/ai';
import {FaSearch} from 'react-icons/fa';
import "../CSS/Menu.css"

const Menu =()=>{
    return(
        <>
            <div className="container-fluid">
                <div className="row" id="deskmenu">
                    <div className="col-12 m-0 p-0">
                    <div className='Menu'>
                        <img src={logo} alt="logo" style={{width:"240px",float:"left"}} className="logo"></img>
                            <nav>
                                <ul type="none"> 
                                <li><NavLink to="/home" className="nav" id="active">Home</NavLink></li>
                                <li><NavLink to="/themes" className="nav">Themes</NavLink></li>
                                {/* <li><NavLink to="/theme-info" className="nav">Theme Info</NavLink></li> */}
                                <li><NavLink to="/about" className="nav">About</NavLink></li>
                                <li><NavLink to="/contact" className="nav">Contact</NavLink></li>
                                <li><NavLink to="/blog" className="nav">Blog</NavLink></li>
                                {/* <li><NavLink to="/blog-read" className="nav">Blog Read</NavLink></li>  */}
                                <li><NavLink to="/help" className="nav">Help</NavLink></li>
                                <li><NavLink to="/cart" className="nav">Cart</NavLink></li>
                                <li><NavLink to="/checkout" className="nav">Checkout</NavLink></li>
                                {/* <li><NavLink to="/payment" className="nav">Payment</NavLink></li> */}
                                <li><NavLink to="/login" className="nav">Login</NavLink></li>
                                <li><NavLink to="/signup" className="nav">Sign-up</NavLink></li>
                                {/* <li><NavLink to="/account" className="nav">Account</NavLink></li> */}
                                {/* <li><NavLink to="/admin" className="nav">Admin</NavLink></li>
                                 <li><NavLink to="/developer" className="nav">Developer</NavLink></li>
                                <li><NavLink to="/developer-panel" className="nav">Developer Panel</NavLink></li> */}
                                {/* <li><NavLink to="./" className="nav cart-icon"><i><AiOutlineShoppingCart/></i>$89</NavLink></li>*/}
                                <input type="text" id="search" name="search" className="search"></input>
                                <li><NavLink to="#" className="nav search-icon"><i><FaSearch/></i></NavLink></li> 

                                </ul>   
                            </nav>
                        </div>
                    </div>
                </div>

                <div className="row" id="resmenu">
                    <div className="col-12 m-0 p-0">
                        <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <a class="navbar-brand" href="#"><img src={logo} style={{width: "250px",height:"50px",float:"left"}}></img></a>
                        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
                            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">

                            <li class="nav-item active">
                                <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Elements</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Portfolio</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Themes</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">About</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Blog</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Help</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Shop</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Login</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Sign-up</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link disabled">Disabled</a>
                            </li>
                            
                            </ul>
                            <form class="form-inline my-2 my-lg-0">
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                            <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                            </form>
                        </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Menu;