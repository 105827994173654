import React from "react";
import {useState} from "react";
import Grid3Call from "./Grid3Call";
import "../CSS/Grid3.css";

import {FaShoppingCart} from "react-icons/fa";
const Grid3=()=>{
    const [item,setItem] = useState(Grid3Call)
    return(
        <>
            <div className="container margin">
                <div className="row">
               {
                item.map((elem)=>{
                    const {id,img,title,subtitle,price1,price2,rating,cart,preview} = elem;
                    return(
                        <div className="col-12 col-md-3">
                            <div className="box">
                            <img src={img}></img>
                            <h6 className="text-nowrap">{title}</h6>
                            <div className="box-div"><p>by <i>{subtitle}</i></p></div>
                            <div className="box-div2"><del><h4>{price1}</h4></del><h4 className="box-div2-price">{price2}</h4></div>
                            <div className="d-flex">
                            <a src={cart}><button className="box-button1"><FaShoppingCart /></button></a>
                            <a src={preview}><button className="box-button2">Live Preview</button></a>
                            </div></div>
                        </div>
                    )
                })
               }
            {/* {
                item.map((elem)=>{
                    const {id,img,title,subtitle,price1,price2,rating,cart,preview} = elem;
                    return(
                        <>
                        <div className="col-12 col-md-3">
                            <div className="box">
                            <img src={img}></img>
                            <h6 className="text-nowrap">{title}</h6>
                            <div className="box-div"><p>by <i>{subtitle}</i></p></div>
                            <div className="box-div2"><del><h4>{price1}</h4></del><h4 className="box-div2-price">{price2}</h4></div>
                            <div className="d-flex">
                            <a src={cart}><button className="box-button1"><FaShoppingCart /></button></a>
                            <a src={preview}><button className="box-button2">Live Preview</button></a>
                            </div></div>
                        </div>
                        </>
                    )
                })
            } */}
                </div>
            </div>
            
        </>
    )
}
export default Grid3;