import React from "react";
import "../CSS/AccountInfo.css";
const BillingAddress1 =()=>{
    return(
        <>  
        <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-md-12 col-sm-12 body m-0 p-0">
                
                <form>
                    <h2>BILLING DETAILS</h2>
                    <div className="d-flex">
                        <div className="mar1">
                            <label>First Name</label><br></br>
                            <input type="text"></input>
                        </div>
                        <div>
                            <label>Last Name</label><br></br>
                            <input type="text"></input>
                        </div>
                    </div>

                    <div  className="d-flex">
                        <div className="mar1">
                            <label>Country / Region</label><br></br>
                            <input type="text"></input>
                        </div>
                        <div>
                            <label>State</label><br></br>
                            <input type="text"></input>
                        </div>
                    </div>

                    <div  className="d-flex">
                        <div className="mar1">
                            <label>Street address</label><br></br>
                            <input type="text"></input>
                        </div>

                        <div>
                            <label>Town / City</label><br></br>
                            <input type="text"></input>
                        </div>
                    </div>

                    <div  className="d-flex">
                        <div className="mar1">
                            <label>PIN Code</label><br></br>
                            <input type="text"></input>
                        </div>

                        <div>
                            <label>Phone Number</label><br></br>
                            <input type="text"></input>
                        </div>
                    </div>
                        
                    <div>
                        <label className="email-font1">Email</label><br></br>
                        <input type="email" className="email1"></input>
                    </div>

                    <div className="mar1">
                        <button>Save</button><br></br>   
                    </div>                        
                </form>

        </div>
        </div>
        </div>
             
        </>
    )
}
export default BillingAddress1;