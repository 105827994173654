import React from "react";
import '../CSS/About1.css';
import {HiArrowRight} from 'react-icons/hi';
import {HiOutlineSquare2Stack} from 'react-icons/hi2'
import {DiDigitalOcean} from 'react-icons/di'
const About1=()=>{
    return(
        <>
        <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-md-12 col-sm-12 m-0 p-0">

        <div className="about1" style={{backgroundImage:"url('About.jpg')"}}>
                <div className="about1
                1">
                    <h4><HiOutlineSquare2Stack/><sub>28+</sub></h4>
                    <p>designers and <br></br>developers</p>
                </div>
                <div className="about12">
                    <h4><DiDigitalOcean/><sub>345+</sub></h4>
                    <p>awards for digital <br></br>innovation</p>
                </div>
        </div>

        <div className="about2">
            <div className="about21">
                <p>LET'S TALK</p>
                <h1>The idea behind Real GPL Theme</h1>
                <div className="about22"><a><button className="about23">Contact Us<HiArrowRight/></button></a></div>
            </div>
            <div className="about24">
                <p>The idea behind <b className="c">“Real GPL Theme”</b> is to help website owners or small developers who have enough knowledge and experience of WordPress that they do not need technical support that developers provide or who are running low on budget & need to buy the plugins for their own and clients projects.</p>
                <p>Sometimes the plugins needed for a client’s website can run into hundreds of dollars which is not even the budget in some developing countries.</p>
                <p>So, this site aims to help knowledgeable people and bloggers, and developers like you and us who don’t need support or automatic updates to save money.</p>
                <p>Most people are familiar with the term ‘open source‘. It means a piece of software or code, that is free to use.</p>
                <p></p>
            </div>
        </div>
        <div className="about2">
            <div className="about21">
                <h1>What does this mean to you?</h1>
            </div>
            <div className="about24">
                <p>It doesn’t mean that developers can’t charge you to buy the code they have developed. What it does mean is that once you have bought that code, you are free to do whatever you like with it.</p>
                <p>If you distribute copies of such a program, whether gratis or for a fee, you must give the recipients all the rights that you have. You must make sure that they, too, receive or can get the source code. And you must show them these terms so they know their rights.</p>
                <p>So, it’s perfectly legal to download the plugins and themes from this site. Download as many as you like and use them in as many projects as you want.</p>
            </div>
        </div>
        <div className="about2">
            <div className="about21">
                <h1>You’re free to use, copy, study, change, improve the products as you like.</h1>
            </div>
            <div className="about24">
            <p>WordPress is open source software and is founded upon the GPL (General Public Licence) that you can read more about over at the WordPress website - <a href="https://wordpress.org/about/license/">here</a></p>
            <p>The main point to note is that any software that is built upon WordPress must carry forward the original GPL license.</p>  
            <p>We feel strongly that plugins and themes are derivative work and thus inherit the GPL license</p>
            </div>
        </div>
        <div className="about2">
            <div className="about21">
                <h1>Who are we?</h1>
            </div>
            <div className="about24">
            <p>We are a company that specializes in the sale of the software needed for the development of websites. These are WordPress themes and plugins, PHP-scripts, JavaScript.</p>
            <p>We launched the project in 2019 and now it is one of the most popular websites that provide the best digital products for website design on the market.</p>
            <p>Our goal is to provide any user with an opportunity to get acquainted with the premium products at an affordable price!</p>
            <p>We’ve put together the latest versions of the best premium –</p> 
            <p><b>WordPress templates and plugins, PHP-Scripts, Javascript and make our website as user-friendly as possible, including no advertising, direct links for downloading easy search, and navigation.</b></p>
            </div>
        </div>

        </div>
        </div>
        </div>
        </>
    )
}
export default About1;