import React from "react";
import "../CSS/Themeinfo.css";
import {MdViewQuilt} from "react-icons/md";
import {FaShoppingCart} from "react-icons/fa";
const ThemeInfo=()=>{
    return(
    <>
    <div className="container-fluid" style={{backgroundColor: "rgb(241, 239, 239)"}}>
    <div className="row">
    <div className="col-12 col-md-12 col-sm-12 m-0 p-0">
        <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="./">Home</a></li>
                <li class="breadcrumb-item"><a href="./themes">Themes</a></li>
                <li class="breadcrumb-item active" aria-current="page">Summary</li>
            </ol>
        </nav>
        </div>
        <div className="d-flex m-0 p-0">
            <div className="image">
                <a href="./"><img src="img13.png"></img></a>
                <div>
                <a src="./themes"><button className="box-button1">Cart <FaShoppingCart /></button></a>
                <a src="./themes"><button className="box-button2"><MdViewQuilt/>Live Preview</button></a>
                </div>
            </div>
            
            <div className="info">
                <h5>Panda Theme GPL v1.4.0 – Creative Marketing Agency & SEO Theme.</h5>
                <p>Panda Theme GPL is best suited for contemporary marketing studio and creative agencies, web designer’s
                 portfolio, marketing bureau, and advertising firm, website building, SEO, SMM, etc.</p>
                <h5>Shipping & Handling</h5>
                <p>For all the orders containing only Gift Cards, standard shipping cost is US$25. Gift Cards are shipped 
                separately from merchandise and cannot be delivered to a P.O. Box.</p>
                <ul>
                    <li>Unlimited Website Usage – Personal & Clients</li>
                    <li>Original GPL Product From the Developer</li>
                    <li>Quick help through Email & Support Tickets</li>
                    <li>Get Regular Updates For 1 Year</li>
                    <li>Last Updated – Oct 4, 2022 @ 12:12 PM</li>
                    <li><a href="./themes">Developer Live Preview</a></li>
                    <li><a href="./themes">Get this Product for Free in Membership</a></li>
                </ul>
            </div>
        </div>
    </div>
    </div>
    </div>
    </>
    )
}
export default ThemeInfo;