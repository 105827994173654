import React,{useState} from "react";
import Grid2Call from "./Grid2Call";
import "../CSS/Grid2.css";
const Grid2 =()=>{
    const[item,setItem]=useState(Grid2Call);
    return(
        <>
        <div className="container margin">
        <div className="row">
        
            {
                item.map((elem)=>{
                const{id,url,img,urlb1,urlb2,title,para}=elem;
                return(
                    <>
                    
                    <div className="col-12 col-md-4 col-sm-12">
                        <div className="card2">
                            <a href={url}><img src={img} width={"320px"}></img></a>
                            <p className="card2-title">{title}</p>
                            <p className="card2-para">{para}</p>
                            <a href={urlb1}><button className="card2-b-1">View</button></a>
                            <a href={urlb2}><button className="card2-b-2">Buy</button></a>
                        </div>
                    </div>
                   
                    </> 
                    )
                }               
            )
            }
        </div>
        </div>
        
        </>
        
    );
}
export default Grid2;