import React from "react";
import {HiOutlineClipboard} from 'react-icons/hi';
import {IoIosNotificationsOutline} from 'react-icons/io';
import {MdOutlineCardMembership,MdContactSupport} from 'react-icons/md';
import {AiOutlineFileDone} from 'react-icons/ai';
import {GoCloudDownload} from 'react-icons/go';
import {FaRegAddressCard} from 'react-icons/fa';
import {MdOutlinePayment,MdOutlineAccountCircle} from 'react-icons/md';
import {FiHeart} from 'react-icons/fi';
import {GoSignOut} from 'react-icons/go';
import "../CSS/Dashboard.css";
const Dashboard=()=>{
    return(
        <>
            <div className="containe-fluid">
            <div className="row d-flex m-0 p-0 ">

                <div className="col-12 col-md-4">
                <div className="dashbord-sidebar">
                    <ul type="none">
                        <li className="heading">Manage Account</li>
                        <li> <a href="./account" className="active1"><i><HiOutlineClipboard/></i>Dashboard</a></li>
                        <li> <a href="./account"><i id="i"><IoIosNotificationsOutline/></i>Notification<span class="notifi">5</span></a></li>
                        <li> <a href="./account"><i id="i"><MdOutlineCardMembership/></i>Membership</a></li>
                        <li> <a href="./"> <i id="i"><AiOutlineFileDone/></i>Order</a> </li>
                        <li> <a href="./"> <i id="i"><GoCloudDownload/></i>Download</a> </li>
                        <li> <a href="./billing-address"> <i id="i"><FaRegAddressCard/></i>Address</a> </li>
                        <li> <a href="./"> <i id="i"><MdOutlinePayment/></i>Payment Method</a> </li>
                        <li> <a href="./info"> <i id="i"><MdOutlineAccountCircle/></i>Account Detail</a> </li>
                        <li> <a href="./support-ticket"> <i id="i"><MdContactSupport/></i>Support Ticket</a> </li>
                        <li> <a href="./"> <i id="i"><FiHeart/></i>Wishlist</a> </li>
                        <li> <a href="./"> <i id="i"><GoSignOut/></i>Sign Out</a> </li>
                    </ul>
                </div>
                </div>

                <div className="col-12 col-md-8 top">

                    <div className="d-flex">
                        <div className=" col-sm sec1">
                            <i><HiOutlineClipboard/></i>
                            <p>Dashboard</p>
                        </div>

                        <div className="col-sm sec1">
                            <i><IoIosNotificationsOutline/></i>
                            <p>Notification</p>
                        </div>

                        <div className="col-sm sec1">
                            <i><MdOutlineCardMembership/></i>
                            <p>Membership</p>
                        </div>

                        <div className="col-sm sec1">
                            <i><AiOutlineFileDone/></i>
                            <p>Order</p>
                        </div>
                    </div>

                    <div className="d-flex">

                        <div className="col-sm sec1">
                            <i><GoCloudDownload/></i>
                            <p>Download</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><FaRegAddressCard/></i>
                            <p>Address</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><MdOutlinePayment/></i>
                            <p>Payment Method</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><MdOutlineAccountCircle/></i>
                            <p>Account Detail</p>
                        </div>

                    </div>

                    <div className="d-flex">    
                        <div className="col-sm sec1">
                            <i><MdContactSupport/></i>
                            <p>Support Ticket</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><FiHeart/></i>
                            <p>Wishlist</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><GoSignOut/></i>
                            <p>Sign Out</p>
                        </div>
                        <div className="col-sm sec1">
                            <i><GoSignOut/></i>
                            <p>Sign Out</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            </div>
        </>
    )
}
export default Dashboard;