import React from "react";
import "../CSS/Themeinfo2.css";
// import "../JS/ThemeInfo2.js";

class ThemeInfo2 extends React.Component{

    Description = () =>{
        if(document.getElementById('a').style.display === 'block'){
            document.getElementById('a').style.display = 'none';
            document.getElementById('b').style.display = 'none';
            document.getElementById('c').style.display = 'none';
            document.getElementById('d').style.display = 'none';
            document.getElementById('e').style.display = 'none';
        }else{
            document.getElementById('a').style.display = 'block';
            document.getElementById('b').style.display = 'none';
            document.getElementById('c').style.display = 'none';
            document.getElementById('d').style.display = 'none';
            document.getElementById('e').style.display = 'none';
        }
    }

    Information = () =>{
        if(document.getElementById('b').style.display === 'block'){
            document.getElementById('b').style.display = 'none';
            document.getElementById('a').style.display = 'none';            
            document.getElementById('c').style.display = 'none';
            document.getElementById('d').style.display = 'none';
            document.getElementById('e').style.display = 'none';
        }else{
            document.getElementById('b').style.display = 'block';
            document.getElementById('a').style.display = 'none';
            document.getElementById('c').style.display = 'none';
            document.getElementById('d').style.display = 'none';
            document.getElementById('e').style.display = 'none';
        }
    }

    Information1 = () =>{
        if(document.getElementById('c').style.display === 'block'){
            document.getElementById('a').style.display = 'none';
            document.getElementById('b').style.display = 'none';
            document.getElementById('c').style.display = 'none';
            document.getElementById('d').style.display = 'none';
            document.getElementById('e').style.display = 'none';
        }else{
            document.getElementById('c').style.display = 'block';
            document.getElementById('a').style.display = 'none';
            document.getElementById('b').style.display = 'none';
            document.getElementById('d').style.display = 'none';
            document.getElementById('e').style.display = 'none';
        }
    }

    Information2 = () =>{
        if(document.getElementById('d').style.display === 'block'){
            document.getElementById('a').style.display = 'none';
            document.getElementById('b').style.display = 'none';
            document.getElementById('c').style.display = 'none';
            document.getElementById('d').style.display = 'none';
            document.getElementById('e').style.display = 'none';
        }else{
            document.getElementById('d').style.display = 'block';
            document.getElementById('a').style.display = 'none';
            document.getElementById('b').style.display = 'none';
            document.getElementById('c').style.display = 'none';
            document.getElementById('e').style.display = 'none';
        }
    }

    Information3 = () =>{
        if(document.getElementById('e').style.display === 'block'){
            document.getElementById('a').style.display = 'none';
            document.getElementById('b').style.display = 'none';
            document.getElementById('c').style.display = 'none';
            document.getElementById('d').style.display = 'none';
            document.getElementById('e').style.display = 'none';
        }else{
            document.getElementById('e').style.display = 'block';
            document.getElementById('a').style.display = 'none';
            document.getElementById('b').style.display = 'none';
            document.getElementById('c').style.display = 'none';
            document.getElementById('d').style.display = 'none';
        }
    }

    render(){
    return(
    <>
    <div className="container-fluid">
    <div className="row">
    <div className="col-12 col-md-12 col-sm-12 new-div">
        <div className="detail">
            <nav>
                <ul type="none" className="d-flex">
                    <a href="#a" onClick={this.Description} data-toggle="collapse" data-target="#a"><li>Description</li></a>
                    <a href="#b" onClick={this.Information} data-toggle="collapse" data-target="#b"><li>Additional Information</li></a>
                    <a href="#c" onClick={this.Information1} data-toggle="collapse" data-target="#c"><li>Brand</li></a>
                    <a href="#d" onClick={this.Information2} data-toggle="collapse" data-target="#d"><li>Coupon</li></a>
                    <a href="#e" onClick={this.Information3} data-toggle="collapse" data-target="#e"><li>Note</li></a>
                </ul>
            </nav>
        </div>

        <div  id="a" className="collapse ">
            <h5>Panda – Creative Marketing Agency & SEO Theme GPL Overview:</h5>
            <p>1Panda Theme GPL is best suited for contemporary marketing studio and creative agencies, web designer’s portfolio, marketing bureau, and advertising firm, digital & graphic design, website building, SEO, SMM, etc.</p>
            <p>2The theme is also good for business consulting, investment advising, corporate and individual companies, financial company, strategy planning, web, and creative agency, startup company, financial manager and adviser, business insider, money manager, freelancer, lawyer, business coach, marketing and advertising services & analysis, financial and investment consulting, etc. It is also great for marketing blogs and business news.</p>
            <p>3The theme is also good for business consulting, investment advising, corporate and individual companies, financial company, strategy planning, web, and creative agency, startup company, financial manager and adviser, business insider, money manager, freelancer, lawyer, business coach, marketing and advertising services & analysis, financial and investment consulting, etc. It is also great for marketing blogs and business news.</p>
            <p>4The theme is also good for business consulting, investment advising, corporate and individual companies, financial company, strategy planning, web, and creative agency, startup company, financial manager and adviser, business insider, money manager, freelancer, lawyer, business coach, marketing and advertising services & analysis, financial and investment consulting, etc. It is also great for marketing blogs and business news.</p>
        </div>

        <div id="b" className="collapse">
            <div className="row1">
                <div className="col-12 col-md-6 col-sm-12 column column1">
                    <p>Buy <b>Astra PRO Addon Premium GPL Latest Version </b>and join the club of more than 23000 happy customers.</p>
                    <p>This GPL Product is the <b>EXACT same theme as sold by the developer </b>and the original company.</p>
                    <p>Read our <a href="./a">Terms & Conditions</a> and <a href="./elements">FAQ</a> before purchase.</p>
                    <ol>
                        <li>Astra uses Vanilla JavaScript that prevents render blocking jQuery to come in the way and affect performance.</li>
                        <li>Astra requires less than 50KB of resources as compared to 100s required by the other WordPress themes.</li>
                        <li>Astra uses default WordPress data and follows the best coding standards to make sure that every piece of code is optimized.</li>
                        <li>Astra is built for speed and fully optimized for performance. Being the most lightweight theme, it loads in less than 0.5 seconds!</li>
                        <li>Use layouts to manage the layout of the website container such as the headers, blogs, single pages, posts, etc.</li>
                        <li>Astra comes with several inbuilt header and footer options that can be extended further with the Astra Pro addon.</li>
                        <li>Create beautiful templates with your favorite page builder or drop in a shortcode to include a layout within a mega menu on your website.</li>
                        <li>Astra is completely WooCommerce ready and helps you build and design your store through the customizer itself. You can build a fast and attractive store today!</li>
                        <li>With Astra, you can create page-specific sidebars that can override the settings and customizations of the default sidebar on your website.</li>
                        <li>This is a header that sticks on top of the page even when the user scrolls. It allows quick access to the menu from any part of the page.</li>
                    </ol>
                    <br></br>
                    <h5>LearnDash Integration</h5><br></br>
                    <p>The Astra LearnDash Integration makes it easier for you to create and design a beautiful website for offering an online course.</p>
                    <p>Astra GPL gives you several design options and features that focus more on providing a better learning experience.</p>
                    <p>Want to create a distraction-free, conversion-oriented website for your next online course with LearnDash? Combine that with Astra and explore many customizations that will help you do so.</p> 
                    <h5>LifterLMS Integration</h5>
                    <p>The Astra LifterLMS integration lets you host the best online learning experience for students accessing your website. Astra makes sure you get complete design freedom to customize everything on your website and make it look good.</p>
                    <p>Features like distraction-free learning, a conversion-oriented checkout page, and more let you create a targeted website that focuses on learning and bringing in higher conversions.</p>
                </div>

                <div className="col-12 col-md-6 col-sm-12 column column2">
                    <h5>Mega Menu</h5>
                    <p>The Astra LifterLMS integration lets you host the best online learning experience for students accessing your website. Astra makes sure you get complete design freedom to customize everything on your website and make it look good.</p>
                    <p>Features like distraction-free learning, a conversion-oriented checkout page, and more let you create a targeted website that focuses on learning and bringing in higher conversions.</p>
                    <h5>Mega Menu</h5>
                    <p>With Astra, you can create interactive mega menus that are user-friendly and attractive.</p>
                    <h5>More Header Designs</h5>
                    <p>You can choose from various headers like a sticky header, a transparent header, etc.</p>
                    <h5>Spacing Control</h5>
                    <p>Manage spacing within layouts and modules to create a beautiful and readable website.</p>
                    <h5>WooCommerce Designer</h5>
                    <p>Astra is the perfect WordPress theme to build a fast and clean WooCommerce store quickly.</p>
                    <h5>Footer Widgets</h5>
                    <p>You can choose from various footer layouts and add widgets to make it more attractive.</p>
                    <h5>Hooks & Filters</h5>
                    <p>Hooks and filters allow you to customize everything on a website to make it your own.</p>
                    <h5>Page Headers</h5>
                    <p>Add and customize the above, below, and primary headers on your website pages.</p>
                    <h5>5 Star Support</h5>
                    <p>With a dedicated troop of developers, you can be assured of timely support & updates.</p>
                    <p>Thank you for the visit. We will love to see you again.</p>
                </div>
            </div>
        </div>

        <div id="c" className="align-center  collapse">
            
            <table>
                <tr className="tr1">
                    <td className="td1">Brand</td>
                    <td>Astra</td>
                </tr>
                <tr className="tr2">
                    <td className="td1">Activation</td>
                    <td>Pre-activated</td>
                </tr>
                <tr className="tr3">
                    <td className="td1">License</td>
                    <td>GPL + Original</td>
                </tr>
            </table>

        </div>

        <div id="d" className="align-center  collapse">
            <p>Use coupon code <b>Black20</b> for instant 20% discount on all items | Valid till 30th November</p>
        </div>

        <div id="e" className="align-center  collapse" >
            <p>The product package may contain additional zips. So, make sure to unzip it first before uploading it to your WordPress Website.</p>
        </div>

    </div>
    </div>  
    </div>
    </>
    )
    }
}
export default ThemeInfo2;  