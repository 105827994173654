import React from "react";
import Menu from "../Components/Menu";
import Footer from "../Components/Footer";
import Contact1 from "../Components/Contact1";
const Contact=()=>{
    return(
        <>
        <Menu/>
        <Contact1/>
        <Footer/>
        </>
    )
}
export default Contact;