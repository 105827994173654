import axios from 'axios';

const URL = 'http://localhost:8000';

export const addUser = async(data) =>{
    try{
       return await axios.post(`${URL}/signup`, data);
    }catch(error){
        console.log('Error While Calling By Add user Api', error.message);
    }
}

// export const getUsers = async() =>{
//     try{
//         return await axios.get(`${URL}/all`);
//     }catch(error){
//         console.log('Error While Fetching Data', error);
//     }
// }