import React from "react";
import Menu from "../Components/Menu";
import Footer from "../Components/Footer";
import Blogs from "../Components/Blogs";
const Blog =()=>{
    return(
        <>
            <Menu/>
            <Blogs/>
            <Footer/>
        </>
    )
}

export default Blog;