import React from "react";
const Grid2Call = [
    {
        id:1,
        url:"/",
        img:"img12.png",
        urlb1:"https://blog.hubspot.com/",
        urlb2:"https://blog.hubspot.com/",
        title:"Digital Agency",
        para:"Agency, Studio, Portfolio",
    },
    {
        id:2,
        url:"/",
        img:"img10.png",
        urlb1:"https://blog.hubspot.com/",
        urlb2:"https://blog.hubspot.com/",
        title:"Digital Agency",
        para:"Agency, Studio, Portfolio",
    },
    {
        id:3,
        url:"/",
        img:"img13.png",
        urlb1:"https://blog.hubspot.com/",
        urlb2:"https://blog.hubspot.com/",
        title:"Digital Agency",
        para:"Agency, Studio, Portfolio",
    },
    {
        id:4,
        url:"/",
        img:"img14.png",
        urlb1:"https://blog.hubspot.com/",
        urlb2:"https://blog.hubspot.com/",
        title:"Digital Agency",
        para:"Agency, Studio, Portfolio",
    },
    {
        id:5,
        url:"/",
        img:"img17.png",
        urlb1:"https://blog.hubspot.com/",
        urlb2:"https://blog.hubspot.com/",
        title:"Digital Agency",
        para:"Agency, Studio, Portfolio",
    },
    {
        id:6,
        url:"/",
        img:"img19.png",
        urlb1:"https://blog.hubspot.com/",
        urlb2:"https://blog.hubspot.com/",
        title:"Digital Agency",
        para:"Agency, Studio, Portfolio",
    },
]
export default Grid2Call;