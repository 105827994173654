import React from "react";
import Menu from "../Components/Menu";
import Footer from "../Components/Footer";
import LeftSlidebar from "../Components/LeftSlidebar";
import AccountInfo from "../Components/AccountInfo";

const Info =()=>{
    return(
        <>  
        <Menu/>
        <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-md-12 col-sm-12 d-flex m-0 p-0">

        <div className="col-12 col-md-4 col-sm-12" style={{backgroundColor:"#eee"}}>
        <LeftSlidebar />
        </div>

        <div className="col-12 col-md-8 col-sm-12 m-0 p-0" style={{backgroundColor:"#eee"}}>
        <AccountInfo/>
        </div>    
           
        </div>
        </div>
        </div>  
        
        <Footer />
        </>
    )
}
export default Info;