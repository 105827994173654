import React from "react";
import Footer from "../Components/Footer";
import Menu from "../Components/Menu";
import Checkout1 from "../Components/Checkout1";
const Checkout =()=>{
    return(
        <>
            <Menu/>
            <Checkout1/>
            <Footer/>
        </>
    )
}

export default Checkout;