import React from "react";
import Menu from "../Components/Menu";
import Banner from "../Components/Banner";
import AllCategory from "../Components/AllCategory";
import Grid from "../Components/Grid";
import Grid2 from "../Components/Grid2";
import Grid3 from "../Components/Grid3";
import Footer from "../Components/Footer";
const Home =()=>{
    return(
        <>
            <Menu/>  
            <Banner />
            <AllCategory />
            <Grid />
            <Grid3/>
            <Grid2 />
            <Footer />   
        </>
    )
}

export default Home;