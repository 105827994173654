import React from "react";
import { useState } from "react";
import GridCall from "./GridCall";
import "../CSS/Grid.css";
const Grid=()=>{
    const [item,setItem] = useState(GridCall);
    return(
        <>
          <div className="container grid">
                <div className="row">
                    
            {
                item.map((elem)=>{
                    const{ id,title,discription,url1,url2,image} = elem;
                    return(
                        <>                                
                        <div className="col-12 col-md-4 col-sm-12 ">
                        <div className="card1">
                            <h3>{title}</h3>
                            <p>{discription}</p>
                            <div id="id1">
                            <a src={url1} className="card1url1">Newest</a>
                            <a src={url2} className="card1url2">Bestsellers</a>
                            </div>
                            <div><img src={image} alt="theme" className="card1image"></img></div>
                        </div>
                        </div>
                        </>
                    )
                })
            }
            </div>
            </div>
            
        </>
    )
}
export default Grid;