import React from "react";
import {FaFacebookF} from 'react-icons/fa';
import { BsInstagram } from "react-icons/bs";
import { GrLinkedinOption } from "react-icons/gr";
import { IoLogoYoutube } from "react-icons/io";
import "../CSS/Footer.css";
const Footer=()=>{
    return(
        <div>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-12 flex m-0 p-0">

                        <div class="col-md-3 col-sm-12 footer-area footer1 border-right">
                            <h3>Real Gpl Theme</h3>
                            <p>MB-407, Mahisbathan<br></br>Newtown, KOL 700102</p>
                            <a href="/">SHOW ON MAP</a>
                            <ul type="none" className="social d-flex">
                                <li><FaFacebookF/></li>
                                <li><BsInstagram/></li>
                                <li><GrLinkedinOption/></li>
                                <li><IoLogoYoutube/></li>
                            </ul>
                        </div>    
                            
                        <div class="col-md-3 col-sm-12 footer-area  footer2 border-right">
                            <h5>Need Help</h5>
                            <h4>+(91)-834 015 9701</h4>
                            <p>Monday – Friday: 9:00-20:00 <br></br>Saturday: 11:00 – 15:00</p>
                            <h5>contact@example.com</h5>
                        </div>
                            
                        <div class="col-md-2 col-sm-12 footer-area footer3 border-right">
                            <ul type="none" className="d-inline">
                                <li className="footer-heading">Explore</li>
                                <a href="./theme-info"><li>Theme Info</li></a>
                                <a href="./about" ><li>About</li></a>
                                <a href="./contact" ><li>Contact</li></a>
                                <a href="./blog" ><li>Blog</li></a>
                                <a href="./blog-read" ><li>Blog Read</li></a>
                            </ul>
                        </div>
                            
                        <div class="col-md-2 col-sm-12 footer-area  footer4 border-right">
                            <ul type="none" className="d-inline">
                                <li className="footer-heading">Categories</li>
                                <a href="./help" ><li>Help</li></a>
                                <a href="./cart" ><li>Cart</li></a>
                                <a href="./checkout" ><li>Checkout</li></a>
                                <a href="./payment" ><li>Payment</li></a>
                                <a href="./login" ><li>Login</li></a>
                            </ul>    
                        </div>

                        <div class="col-md-2 col-sm-12 footer-area footer5 ">  
                            <ul type="none" className="d-inline">
                                <li className="footer-heading">Categories</li>
                                <a href="./signup" ><li>Sign-up</li></a>
                                <a href="./account" ><li>Account</li></a>
                                <a href="./admin" ><li>Admin</li></a>
                                <a href="./developer" ><li>Developer</li></a>
                                <a href="./developer-panel" ><li>Developer Panel</li></a>
                            </ul>    
                        </div>

                    </div>
                </div>
            </div>
 
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-12 col-sm-12 footer-bottom1 border-top border-bottom">
                        <span>Copyright © 2022 Real GPL Theme. All rights reserved.</span>    
                    </div>
                </div>
            </div> 


        </div>
    )}
    export default Footer;
            