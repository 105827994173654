const Grid3Call = [
    {
        id:1,
        img:"img10.png",
        title:"Minimog - The Next Generation Shopify Theme",
        subtitle:"ThemeMove in Fashion",
        price1:"$89",
        price2:"$52",
        rating:"4/5",
        cart:"/",
        preview:"/"

    },
    {
        id:2,
        img:"img12.png",
        title:"Minimog - The Next Generation Shopify Theme",
        subtitle:"ThemeMove in Fashion",
        price1:"$89",
        price2:"$52",
        rating:"4/5",
        cart:"/",
        preview:"/"

    },
    {
        id:3,
        img:"img13.png",
        title:"Minimog - The Next Generation Shopify Theme",
        subtitle:"ThemeMove in Fashion",
        price1:"$89",
        price2:"$52",
        rating:"4/5",
        cart:"/",
        preview:"/"

    },
    {
        id:4,
        img:"img14.png",
        title:"Minimog - The Next Generation Shopify Theme",
        subtitle:"ThemeMove in Fashion",
        price1:"$89",
        price2:"$52",
        rating:"4/5",
        cart:"/",
        preview:"/"

    },
    {
        id:5,
        img:"img17.png",
        title:"Minimog - The Next Generation Shopify Theme",
        subtitle:"ThemeMove in Fashion",
        price1:"$89",
        price2:"$52",
        rating:"4/5",
        cart:"/",
        preview:"/"

    },
    {
        id:6,
        img:"img12.png",
        title:"Minimog - The Next Generation Shopify Theme",
        subtitle:"ThemeMove in Fashion",
        price1:"$89",
        price2:"$52",
        rating:"4/5",
        cart:"/",
        preview:"/"

    },
    {
        id:7,
        img:"img14.png",
        title:"Minimog - The Next Generation Shopify Theme",
        subtitle:"ThemeMove in Fashion",
        price1:"$89",
        price2:"$52",
        rating:"4/5",
        cart:"/",
        preview:"/"

    },
    {
        id:7,
        img:"img14.png",
        title:"Minimog - The Next Generation Shopify Theme",
        subtitle:"ThemeMove in Fashion",
        price1:"$89",
        price2:"$52",
        rating:"4/5",
        cart:"/",
        preview:"/"

    },
    {
        id:7,
        img:"img14.png",
        title:"Minimog - The Next Generation Shopify Theme",
        subtitle:"ThemeMove in Fashion",
        price1:"$89",
        price2:"$52",
        rating:"4/5",
        cart:"/",
        preview:"/"

    },
    {
        id:7,
        img:"img14.png",
        title:"Minimog - The Next Generation Shopify Theme",
        subtitle:"ThemeMove in Fashion",
        price1:"",
        price2:"$52",
        rating:"4/5",
        cart:"/",
        preview:"/"

    },{
        id:7,
        img:"img14.png",
        title:"Minimog - The Next Generation Shopify Theme",
        subtitle:"ThemeMove in Fashion",
        price1:"$89",
        price2:"$52",
        rating:"4/5",
        cart:"/",
        preview:"/"

    },{
        id:7,
        img:"img14.png",
        title:"Minimog - The Next Generation Shopify Theme",
        subtitle:"ThemeMove in Fashion",
        price1:"$89",
        price2:"$52",
        rating:"4/5",
        cart:"/",
        preview:"/"

    }
]
export default Grid3Call;