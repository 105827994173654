import React from "react";
import Cart1 from "../Components/Cart1";
import Footer from "../Components/Footer";
import Menu from "../Components/Menu";
const Cart =()=>{
    return(
        <>
            <Menu/>
            <Cart1/>
            <Footer/>
        </>
    )
}

export default Cart;