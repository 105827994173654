import React from "react";

import {HiOutlineClipboard} from 'react-icons/hi';
import {IoIosNotificationsOutline} from 'react-icons/io';
import {MdOutlineCardMembership,MdContactSupport} from 'react-icons/md';
import {AiOutlineFileDone} from 'react-icons/ai';
import {GoCloudDownload} from 'react-icons/go';
import {FaRegAddressCard} from 'react-icons/fa';
import {MdOutlinePayment,MdOutlineAccountCircle} from 'react-icons/md';
import {FiHeart} from 'react-icons/fi';
import {GoSignOut} from 'react-icons/go';

import "../CSS/LeftSlidebar.css";

const LeftSlidebar =()=>{
    return(
        <>
        <div className="containe-fluid">
            <div className="row">
                <div className="col-12 col-md-12 col-sm-12">
                <div className="dashbord-sidebar1">
                    <ul type="none">
                        <li className="heading1">Manage Account</li>
                        <li> <a href="./account" className="active2"><i><HiOutlineClipboard/></i> Dashboard</a></li>
                        <li> <a href="./account"><i id="i"><IoIosNotificationsOutline/></i> Notification<span class="notifi1">5</span></a></li>
                        <li> <a href="./account"><i id="i"><MdOutlineCardMembership/></i> Membership</a></li>
                        <li> <a href="./"> <i id="i1"><AiOutlineFileDone/></i> Order</a> </li>
                        <li> <a href="./"> <i id="i1"><GoCloudDownload/></i> Download</a> </li>
                        <li> <a href="./billing-address"> <i id="i1"><FaRegAddressCard/></i> Address</a> </li>
                        <li> <a href="./"> <i id="i1"><MdOutlinePayment/></i> Payment Method</a> </li>
                        <li> <a href="./info"> <i id="i1"><MdOutlineAccountCircle/></i> Account Detail</a> </li>
                        <li> <a href="./support-ticket"> <i id="i1"><MdContactSupport/></i> Support Ticket</a> </li>
                        <li> <a href="./"> <i id="i1"><FiHeart/></i> Wishlist</a> </li>
                        <li> <a href="./"> <i id="i1"><GoSignOut/></i> Sign Out</a> </li>
                    </ul>
                </div>
                </div>
            </div>
            </div>

        </>
    )
}
export default LeftSlidebar;