import React from "react";
import "../CSS/About2.css";
const About2=()=>{
    return(
        <>
        <div className="container-fluid ">
        <div className="row">
        <div className="col-12 col-md-12 col-sm-12 m-0 p-0 d-flex">

        
        <div className="aboutus" style={{paddingTop:"60px"}}>
            <h4><b>Why choose us?</b></h4>

            <ul>
                <li>Lower price.</li>
                <li>Original files without malware, viruses, or advertising</li>
                <li>All products work on an unlimited number of websites (domains) (unlimited license).</li>
                <li>All products are available through direct links.</li>
                <li>We regularly update products and constantly add new products, which are difficult to find anywhere else!</li>
                <li>We provide remote support</li>
                <li>Free Hosting Offers is also available</li>
            </ul>

            <p><b>On our website you can download premium products from the following developers:</b></p>
            <p>Themeforest Market, Codecanyon Market, AppThemes, AIT Themes Club, Elegant Themes, MyThemeShop, StudioPress, WooThemes (WooCommerce.com), Themify, Yithemes (YITH), iThemes, Modern Tribe, OnTheGoSystems, Rocket Genius, Thrive Themes, WP All Import, Yoast, and others.</p>    
        </div>
    
    
        <div className="d-align" style={{paddingTop:"40px"}}>
            <div className="about-new0">
                <div className="about-new1">
                    <h6 className="about-new2" style={{borderBottom:"1px solid black"}}>STRATEGY</h6>
                    <h3>1-Brand Strategy & Art Direction</h3>
                    <p>Creating a higher spacing and how people move through a unique and impactful campaign.</p>
                </div>
            </div>
            
            <div className="about-new0" style={{marginBottom:"40px"}}>
                <div className="about-new1">
                    <h6 className="about-new2" style={{borderBottom:"1px solid black"}}>STRATEGY</h6>
                    <h3>2-Brand Strategy & Art Direction</h3>
                    <p>Creating a higher spacing and how people move through a unique and impactful campaign.</p>
                </div>
            </div>
        </div>

        <div className="d-align" style={{paddingTop:"40px",marginRight:"40px"}}>
            <div className="about-new0">
                <div className="about-new1">
                    <h6 className="about-new2" style={{borderBottom:"1px solid black"}}>STRATEGY</h6>
                    <h3>3-Brand Strategy & Art Direction</h3>
                    <p>Creating a higher spacing and how people move through a unique and impactful campaign.</p>
                </div>
            </div>
            
            <div className="about-new0" style={{marginBottom:"40px"}}>
                <div className="about-new1">
                    <h6 className="about-new2" style={{borderBottom:"1px solid black"}}>STRATEGY</h6>
                    <h3>4-Brand Strategy & Art Direction</h3>
                    <p>Creating a higher spacing and how people move through a unique and impactful campaign.</p>
                </div>
            </div>
        </div>
        </div>
        </div>
        </div>
        </>
    )
}
export default About2;