import React from "react";
import BlogSingle from "../Components/BlogSingle";
import Footer from "../Components/Footer";
import Menu from "../Components/Menu";
const ReadBlog =()=>{
    return(
        <>
            <Menu/>
            <BlogSingle/>
            <Footer/>
        </>
    )
}

export default ReadBlog;